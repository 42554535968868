import 'dotenv/config';
import { useState } from "react";
import { ReactNode } from 'react';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useContractMethod } from "../hooks";
import { utils } from '@usedapp/core/node_modules/ethers';
const { REACT_APP_PUBLIC_KEY, REACT_APP_CONTENT_HASH, REACT_APP_METADATA_URL } = process.env;

type Props = {
    children?: ReactNode;
};

export default function Mint({ children }: Props) {
    
    const {state, send: safeMint} = useContractMethod("safeMint");

    function handleMint() {
        console.log( REACT_APP_PUBLIC_KEY )
        console.log (REACT_APP_CONTENT_HASH)
        console.log (REACT_APP_METADATA_URL)
        safeMint(1, {value: utils.parseEther(".069")});
    }

    return(
        <Button
        rounded={'full'}
        px={6}
        colorScheme={'teal'}
        bg={'teal.400'}
        _hover={{ bg: 'teal.500' }}
        // variant={'solid'}
        // leftIcon={<AddIcon />}
        onClick={handleMint}>
        Mint
        </Button>
    );

}