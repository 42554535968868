import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import theme from "./theme";
import Navbar from "./components/Navbar"
import Layout from "./components/Layout";
import Hero from "./components/Hero";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import "@fontsource/inter";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider theme={theme}>
      <Navbar>
        <ConnectButton handleOpenModal={onOpen} />
        <AccountModal isOpen={isOpen} onClose={onClose} />
      </Navbar>
      <Hero>

      </Hero>
    </ChakraProvider>
  );
}

export default App;
