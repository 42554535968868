import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Icon,
    IconProps,
  } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Mint from "../components/Mint";

  type Props = {
    children?: ReactNode;
  };
  
  export default function CallToActionWithIllustration({ children }: Props) {
    return (
      <Container maxW={'5xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Big ol' fuckin' {' '}
            <Text as={'span'} color={'teal.400'}>
              Heading Text
            </Text>
          </Heading>
          <Text color={'gray.500'} maxW={'3xl'}>
            Some shit about the product here. Whatever. Smash that Mint button. Thanks.
          </Text>
          <Stack spacing={6} direction={'row'}>
            {/* <Button
              rounded={'full'}
              px={6}
              colorScheme={'teal'}
              bg={'teal.400'}
              _hover={{ bg: 'teal.500' }}>
              Mint
            </Button> */}
            <Mint>
            </Mint>
            <Button rounded={'full'} px={6}>
              Roadmap
            </Button>
          </Stack>
          <Flex w={'full'}>
            <Illustration
              height={{ sm: '24rem', lg: '28rem' }}
              mt={{ base: 12, sm: 16 }}
            />
          </Flex>
        </Stack>
      </Container>
    );
  }
  
  export const Illustration = (props: IconProps) => {
    return (
      <Icon
        width="100%"
        viewBox="0 0 802 648"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <ellipse stroke-width="10" ry="253.5" rx="257" id="svg_3" cy="300" cx="400" stroke="#000" fill="#fff"/>
        <path d="m403,254c2,3 3.61066,6.15927 5,9c1.58411,3.23892 5.19409,7.88855 8,13c1.07602,1.96014 4,6 6,8c2,2 4.5582,5.51929 8,8c1.814,1.30746 6.34619,4.70547 8,7c1.30746,1.814 3,5 5,7c3,3 3.53491,3.31076 5,5c2.07193,2.38895 1.71411,4.21167 4,7c1.79318,2.18735 1.69254,3.186 3,5c0.8269,1.14728 2,2 3,4c1,2 2,3 2,4c0,1 0.77023,1.02676 1,2c0.51373,2.17624 1,3 1,4c0,1 0.22977,3.02676 0,4c-0.51373,2.17624 -2,4 -3,5c-2,2 -4.186,4.69254 -6,6c-2.29453,1.65381 -4.41528,4.18875 -5,5c-1.84903,2.56537 -5,2 -6,2c-2,0 -4,0 -6,0c-2,0 -5.85272,-1.1731 -7,-2c-3.62799,-2.6149 -7,-4 -8,-5c-3,-3 -6,-5 -8,-6c-2,-1 -3.186,-1.69254 -5,-3c-1.14728,-0.8269 -3.58578,-0.58578 -5,-2c-0.70709,-0.70709 -2,-1 -3,-1c-1,0 -1,-1 -2,-1c-1,0 -2,0 -4,0c-2,0 -5.10263,2.36755 -7,3c-4.74341,1.58115 -7.90634,5.10339 -9,6c-2.78833,2.28589 -4,3 -5,3c-1,0 -2,0 -3,0c-1,0 -3,0 -4,0c-2,0 -4.01291,0.16019 -5,0c-3.12143,-0.50653 -5.90634,-2.10339 -7,-3c-2.78833,-2.28589 -6,-3 -7,-4c-1,-1 -2.4588,-2.69345 -3,-4c-0.38269,-0.92389 -0.4588,-1.69345 -1,-3c-0.38269,-0.92389 0.69345,-2.4588 2,-3c1.84775,-0.76538 3,-1 5,-1c1,0 1.29291,-0.29291 2,-1c0.70709,-0.70709 2,0 3,0l1,0" id="svg_6" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m346,347c-2,0 -3.22836,-1.14804 -6,0c-1.30655,0.5412 -3,3 -5,4c-2,1 -3,2 -4,3c-1,1 -2,2 -3,3c-1,1 -1.82443,1.0979 -3,3c-0.52573,0.85065 -1,2 -2,4c-1,2 -3,3 -4,6c-1,3 -2,5 -3,9c-1,4 -0.50732,6.02432 -1,11c-0.50244,5.07422 -1,7 -1,11c0,2 0,4 0,6c0,2 0,4 0,6c0,2 0,4 0,5c0,1 0.4588,2.69345 1,4c0.38269,0.92389 1,2 2,2c1,0 2,0 3,0c4,0 9,0 13,0c10,0 23,0 30,0c12,0 23,0 31,0c3,0 9,0 11,0c6,0 12,0 16,0c8,0 16,0 18,0c6,0 10,0 13,0c1,0 5,0 9,0c3,0 8,0 13,0c2,0 4,0 7,0c1,0 3,0 5,0c2,0 3,0 4,0c1,0 1,-3 1,-6c0,-3 -1.49347,-7.87857 -2,-11c-0.8009,-4.93542 -1.2565,-7.797 -2,-9c-1.66251,-2.69 -3.2565,-5.797 -4,-7c-1.66251,-2.69 -2.83981,-7.01291 -3,-8c-0.50653,-3.12143 -2.4588,-4.69345 -3,-6c-0.38269,-0.92389 -2,-4 -3,-6c-1,-2 -1.61731,-5.07611 -2,-6c-0.5412,-1.30655 -1.23462,-2.15225 -2,-4c-0.5412,-1.30655 -2.4588,-1.69345 -3,-3c-0.38269,-0.92389 -1.186,-1.69254 -3,-3c-1.14728,-0.8269 -1.29291,-1.29291 -2,-2c-0.70709,-0.70709 -1,-1 -2,-1l0,-1l-1,0" id="svg_7" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m378,474c0,1 0.54916,1.95517 4,3c1.91418,0.57956 3.02582,0.67963 5,1c3.12143,0.50653 4.04132,0.84723 8,2c3.03616,0.88412 4,2 5,2c3,0 5,1 8,1c1,0 5.07181,0.30875 10,-1c3.05634,-0.81165 7.21167,-1.71411 10,-4c1.09366,-0.89661 3,-3 5,-4l1,0l0,-1l1,0" id="svg_8" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m310,491c0,1 1.41885,2.41885 3,4c1.58115,1.58115 4.21167,4.71411 7,7c1.09366,0.89661 3.90634,4.10339 5,5c2.78833,2.28589 7.37201,3.3851 11,6c3.4418,2.48071 4.88855,4.19409 10,7c1.96014,1.07599 7.83807,3.46552 13,5c4.88763,1.45294 7.84464,2.54694 14,4c6.8819,1.62457 11,2 20,2c6,0 9,0 14,0c2,0 8,0 15,0c5,0 14.85159,-2.99512 25,-4c3.98053,-0.39417 10.02521,-1.88135 15,-3c4.02264,-0.90454 5.70251,-2.56348 11,-5c5.74594,-2.64276 8.66995,-2.09479 13,-6c3.71298,-3.34869 5.31021,-5.71899 8,-9c2.28589,-2.78833 3.34619,-3.70547 5,-6c1.30746,-1.814 2,-3 3,-3l1,-1l1,-1l0,-1" id="svg_9" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m276,262c1,-1 3,-2 4,-3c2,-2 3.93414,-4.14429 6,-5c2.92157,-1.21014 7.93414,-3.14429 10,-4c5.84314,-2.4203 13.28369,-2.66368 21,-5c2.14014,-0.64798 5,-1 7,-1c2,0 3,0 4,0c1,0 4.07611,-0.38269 5,0c1.30655,0.5412 1.69345,1.4588 3,2c0.92389,0.38269 1.07611,0.61731 2,1c1.30655,0.5412 2.29291,0.29289 3,1c0.70709,0.70711 1,1 2,2c1,1 2.07611,1.61731 3,2c1.30655,0.5412 2,1 3,2c1,1 1.54916,1.95517 5,3c0.95709,0.28979 5,2 6,2l1,1" id="svg_10" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m278,252c0,-1 0.48627,-2.82375 1,-5c1.14877,-4.86624 1,-12 1,-15c0,-5 0.24435,-10.01498 0,-12c-0.50378,-4.09221 -0.1991,-8.06456 -1,-13c-0.50653,-3.12144 -1,-8 -1,-13c0,-2 0,-6 0,-8c0,-4 -0.3782,-8.03609 0,-10c1.01837,-5.28799 4.82443,-9.09789 6,-11c2.62866,-4.25325 6.40402,-7.41156 11,-11c1.11469,-0.87033 4,-2 7,-2c1,0 4,-1 9,-1c3,0 8.10699,-0.91901 12,0c2.17624,0.51375 4.5582,3.51929 8,6c1.814,1.30745 5,4 8,7c2,2 4.8819,7.07278 8,12c1.92807,3.04672 6.14774,7.94341 8,13c1.08768,2.96933 3,9 5,14c2,5 3,8 4,12c1,4 1.9725,6.64749 3,11c0.91901,3.89299 1,6 1,10c0,3 0.48627,4.82375 1,7c0.68927,2.91975 0,4 0,5c0,2 0,3 0,4l0,1" id="svg_11" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m423,253c2,0 3.03873,0.48055 6,0c3.12143,-0.50655 7.64749,-3.97252 12,-5c1.9465,-0.4595 7.06458,-0.19908 12,-1c3.12143,-0.50655 9,-1 16,-1c2,0 4,0 5,0c1,0 3,0 4,0c1,0 1,1 2,1c1,0 1,1 2,1c1,0 1,1 2,1c1,0 1.0535,0.5405 3,1c2.17624,0.51375 4.04132,1.84723 8,3c3.03616,0.88414 4,2 5,2l1,0" id="svg_12" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m426,250c-1,0 -0.73209,-3.04353 -2,-10c-1.65314,-9.07013 -2,-13 -2,-22c0,-8 0,-12 0,-19c0,-8 -0.49622,-12.01761 0,-19c0.50125,-7.05328 1.43289,-9.80243 4,-16c2.42029,-5.84312 2.724,-8.22273 6,-12c2.77979,-3.20512 4.59399,-5.513 7,-7c2.69,-1.66251 5.01947,-1.60585 9,-2c5.07422,-0.50244 10,-1 14,-1c6,0 9.30539,0.36168 14,3c4.35883,2.44963 7.11365,5.89246 9,8c4.27039,4.77115 5.67914,7.65082 8,13c2.89764,6.6786 3.6741,12.64709 5,16c2.23688,5.65654 2.27432,12.88496 3,15c1.65482,4.82303 2.48627,8.82375 3,11c0.68927,2.91975 0.49622,4.90779 1,9c0.48874,3.97003 -0.24435,7.01498 0,9c0.50378,4.09221 1,8 1,9c0,3 -0.22977,6.02675 0,7c0.51373,2.17625 1,4 1,5l0,1" id="svg_13" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m331,205c-1,0 -2.29291,-0.70711 -3,0c-0.70709,0.70711 0,2 0,3c0,2 -0.30746,4.186 1,6c0.8269,1.14726 1.69345,2.4588 3,3c0.92389,0.38269 2,0 3,0c2,0 6,-2 9,-5c1,-1 1.48627,-1.82375 2,-4c0.22977,-0.97325 0,-2 0,-3c0,-2 0,-3 -1,-4c-1,-1 -2,-1 -3,-1c-1,0 -2,0 -3,0c-1,0 -1,1 -1,2c0,1 0,3 1,4c1,1 1,3 2,3c1,0 -1.29291,-0.29289 -2,-1c-0.70709,-0.70711 -1,-1 -2,-1c-1,0 -1,1 -1,2c0,1 2,2 3,2c1,0 1.48627,-0.82375 2,-3c0.22977,-0.97325 0.76538,-4.15224 0,-6c-0.5412,-1.30656 -1,-2 -2,-2c-1,0 -2,0 -3,0c-1,0 -2,0 -2,1c0,1 -1,1 -1,2l-1,1l-1,1l0,1" id="svg_14" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m479,201c-1,0 -2,0 -3,0c-1,0 -3,0 -4,0c-2,0 -2,1 -2,2c0,1 0.29291,1.29289 1,2c0.70709,0.70711 0.82376,2.48625 3,3c0.97324,0.22975 2.15225,0.23463 4,1c1.30655,0.5412 3,1 5,1c1,0 5.38687,-1.9176 8,-3c0.92389,-0.38269 1,-2 1,-5c0,-1 0.76538,-3.15224 0,-5c-0.5412,-1.30656 -3.15225,-1.23463 -5,-2c-1.30655,-0.5412 -2,-1 -4,-1c-2,0 -3.15225,-0.76537 -5,0c-1.30655,0.5412 -2.69254,3.186 -4,5c-0.8269,1.14726 -1.38269,2.07613 -1,3c0.5412,1.30656 3,1 5,1c1,0 2,0 2,-1c0,-1 -0.14728,0.1731 1,1c1.814,1.30745 3,1 5,1c2,0 2.38269,-1.07613 2,-2c-0.5412,-1.30656 -2,-1 -4,-2l-1,0" id="svg_15" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m281,185c2,0 4.05664,-1.91753 7,-4c4.0817,-2.88786 5,-5 6,-6c2,-2 5,-3 9,-5c2,-1 8,-3 14,-5c3,-1 6.90778,-3.49623 11,-4c2.97751,-0.36655 4,-1 7,-1c1,0 4,0 7,0c2,0 3,0 4,0l3,0l1,1l1,0" id="svg_16" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m426,167c2,0 5.80963,-0.56731 14,-3c8.83795,-2.62503 12.15686,-3.5797 18,-6c2.06586,-0.85571 5.22836,-0.85194 8,-2c1.30655,-0.5412 5.03873,-0.51945 8,-1c3.12143,-0.50655 5,-1 7,-1c2,0 3,0 4,0l1,0l1,0l1,0" id="svg_17" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m255,155c5,-2 11.09595,-5.79799 26,-13c8.05331,-3.89156 24.06616,-9.82648 40,-16c5.02145,-1.94554 13,-4 15,-4c1,0 2,0 5,0l2,0l1,0" id="svg_18" stroke-width="20" stroke="#000" fill="none"/>
        <path d="m404,126c0,-1 3.07181,-2.69125 8,-4c6.11267,-1.62331 23,-9 43,-15c10,-3 25.06827,-4.35873 29,-9c0.64636,-0.76302 1,-1 2,-1l1,0" id="svg_19" stroke-width="20" stroke="#000" fill="none"/>
        <path d="m449,148c-1,0 -3,0 -4,0c-1,0 -1.29291,0.29289 -2,1c-0.70709,0.70711 -1.29291,1.29289 -2,2c-0.70709,0.70711 -1,1 1,1c1,0 2,0 1,0c-1,0 -1.69345,0.4588 -3,1c-0.92389,0.38269 -1,1 -2,1c-1,0 -1.29291,0.29289 -2,1c-0.70709,0.70711 -1.4588,0.69344 -2,2c-0.38269,0.92387 -0.70709,2.29289 0,3c0.70709,0.70711 3.07611,0.38269 4,0c1.30655,-0.5412 2,-2 3,-2c1,0 1.29291,-0.29289 2,-1c0.70709,-0.70711 1,-1 2,-1c1,0 2.07611,-0.61731 3,-1c1.30655,-0.5412 1,-2 2,-2c1,0 2.38687,-1.9176 5,-3c1.84775,-0.76537 1.82376,-1.48625 4,-2c0.97324,-0.22975 1.69345,-1.4588 3,-2c0.92389,-0.38269 1.29291,-0.29289 2,-1c0.70709,-0.70711 2,0 3,0c1,0 1,-1 2,-1c1,0 2,0 3,0c1,0 2,0 3,0l1,0" id="svg_20" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m300,160c-2,1 -4,2 -7,5c-1,1 -4.48627,2.82375 -5,5c-0.22977,0.97325 0.0535,2.4595 2,2c2.17624,-0.51375 5,-1 5,-2c0,-1 -2,0 -4,0c-2,0 -3.82376,1.48625 -6,2c-0.97324,0.22975 -3.5412,0.69344 -3,2c0.38269,0.92387 2,0 3,0c2,0 6.08582,0.57957 8,0c3.45084,-1.04483 5.186,-2.69255 7,-4c1.14728,-0.8269 3.29291,-1.29289 4,-2c0.70709,-0.70711 3.186,-0.69255 5,-2c1.14728,-0.8269 2,-2 3,-2c1,0 2.29291,0.70711 3,0c0.70709,-0.70711 1.22977,-1.02675 1,-2c-0.51373,-2.17625 -1.69345,-2.4588 -3,-3c-0.92389,-0.38269 -1,-1 -2,-1c-1,0 -2,0 -3,0c-1,0 -2,0 -3,0c-1,0 -2.29291,0.70711 -3,0c-0.70709,-0.70711 0.02249,-1.63345 3,-2c4.09222,-0.50377 5.92578,-1.49756 11,-2c3.98053,-0.39415 5,0 7,0c2,0 1,0 0,0c-1,0 -2,0 -1,0c2,0 4,1 6,1l1,0l1,0" id="svg_21" stroke-width="10" stroke="#000" fill="none"/>
        <path d="m469,147c-1,0 -2,0 -3,0c-1,0 -2,0 -3,0c-1,0 -1,1 0,1c1,0 2,0 3,0c1,0 1,1 2,1c1,0 2.02676,0.22975 3,0c2.17624,-0.51375 3,-1 4,-2l1,0l0,-1" id="svg_22" stroke-width="10" stroke="#000" fill="none"/>
      </Icon>
    );
  };